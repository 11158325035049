import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('./components/HomePage.vue'),
  },
    {
    path: '/job-application-form/:jobType',
    name: 'JobApplicationForm',
    component: () => import('./components/JobApplicationForm.vue'),
    },
    {
      path: '/video-upload/:email',
      name: 'VideoUpload',
      component: () => import('./components/VideoUpload.vue'),
    },
    {
        path: '/job-application-acknowledgement',
        name: 'JobApplicationAcknowledgement',
        component: () => import('./components/JobApplicationAcknowledgement.vue'),
    },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router
