<template>
  <div id="app">
  <header>

  </header>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Sportech',
  },
};

</script>
<style>

body {
  background-color: black !important;
  color: white;            /* Setting the default text color to white */
  font-family: Arial, sans-serif; /* Example font */
  margin: 0;
  padding: 0;
}

/* Importing the fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #aaa; /* Light gray for body text */
  background-color: #000; /* Black background */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  color: #fff; /* White for headings */
  margin-top: 0;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

a {
  color: #48d1cc; /* Teal color for links */
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #20b2aa; /* Darker teal for hover state */
}

small, .small {
  font-size: 14px;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  body {
    font-size: 14px;
  }

  small, .small {
    font-size: 12px;
  }
}

</style>
